.Skills {
    height : 80vh;
    background-color : #1A1A1D;
    display : grid;
    width : 100vw;
}

.skill-main{
    display : grid;
    grid-template-rows : 300px auto;
    justify-self : center;
    height : 100%;
    width : 70%;
    
}

.skills-heading{
    width : 100%;
    align-self : center;
    justify-self : center;
    display : grid;
    grid-template-columns : 1fr 1fr 1fr;
    margin : 0;
    color : rgb(216, 216, 200);
   
}
.skills-heading-text{
    font-size : clamp(16px , 3vw, 40px);
}
.skill-left-line{
    width : 100%;
    height : 2px;
    background-color : #C3073F;
    align-self : center;
    justify-self : center;
}

.skill-right-line{
    width : 100%;
    height : 2px;
    background-color : #C3073F;
    align-self : center;
    justify-self : center;
}
.skills-icons{
    align-self : start;
    justify-self : center;
    height : 100%;
    width : 80%;
    display : grid;
    grid-template-columns : 1fr 1fr 1fr;
    justify-content: center;
    grid-row-gap : 40px;
    align-content : start;
}
.skill-icon{
    align-self : center;
    justify-self : center;
    height : clamp(40px , 6vw, 80px);
    width : clamp(40px , 6vw, 80px);
}