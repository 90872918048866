.Projects {
    width : 100vw;
    background-color : rgb(200, 230, 238);
    display : flex;
    justify-content : center;
    background-color : #1A1A1D;
}
.Projects *{
   
    color : rgb(216, 216, 200);
}

.projects-main{
    margin-top : 4rem;
    width : 50%;
    position : relative;
}
.project{
    padding : 30px 0 30px 0 ;
    display :grid;
    align-items : center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows : 1fr;
   
}
.project-heading{
    position :relative;
    display : grid;
    grid-template-columns : 1fr 1.2fr
}
.heading-text{
    text-align : left !important;
    font-size : clamp(18px, 3vw, 30px);
    font-weight : 700;
    color : rgb(237, 238, 231);
}

.heading-line{
    align-self : center;
    height : 1px;
    width : 100%;
    background-color: #C3073F;
}
.image-container{
    grid-column : 1 / 2;
    position : relative;
}


.project-image{
    height : 100%;
    width : 100%;
    position : relative;
    background-color: rgb(173, 201, 173);
}

.image-container::after{
    content : "";
   
    position : absolute;
    top : 0;
    height : 100%;
    width : 100%;
    background-color : rgb(228, 228, 197);;
    opacity : 0.77;
    left : 0%;
    z-index : 4;
    transition : all 0.2s linear;
}

.image-container:hover::after{
    content : '';
    cursor : pointer;
    opacity : 0%;
}

.project-details{
    
    grid-column : 2 / 3
}

.right-details{
    grid-column : 2 / 3;
}

.featured-project{
    text-align : right !important;
    margin : 0;
    font-size : 15px;
    color : #C3073F;
    position : relative;
}

.project-name{
    text-align : right !important;
    margin : 0;
    font-size : 24px;
    font-weight : 700;
    padding : 10px 0 10px 0;
}

.project-description{
    text-align : left !important;
    line-height: 1.6;
    background-color : #1f1e1e;
    font-size : 15px;
    padding : 15px 10px 15px 50px;
    width : calc(50px + 100%);
    position : relative;
    z-index : 5;
    right : 100px;
    /* box-shadow : 0 0 10px 1px #C3073F; */
}

.tech-stack{
    display : flex;
    flex-grow : 1;
    width : 60%;
    justify-content : space-between;
    margin-left : auto;
    
}

.tech-stack p {
    color : #C3073F;
}

.code-link{
    display : flex;
    flex-grow : 1;
    width : 30%;
    justify-content : space-between;
    margin-left : auto;
}

.code-link a{
    border : 1px solid rgb(228, 228, 197);
    padding : 5px 8px 5px 8px;
    color : rgb(228, 228, 197);
    text-decoration: none;
    border-radius : 5px;
}

.left-image{
    grid-column : 2 / 3;
    grid-row : 1 / 2;
}

.left-details{
    grid-column : 1 / 2;
    /* grid-row : 1 / 2; */
}

.left{
    text-align: left !important;
    
}

.left-description{
    width : 120%;
    padding-left : 20px;
    left : 0;
}

.left-tech-stack{
    margin-left : 0;
   
}
.left-code-link{
    margin-left : 0;
    
}

@media (max-width : 1260px){
    .Projects .tech-stack{
        width : 70%;
    }

    .Projects .projects-main{
        width : 80%;
        
    }
    /* .Projects .image-container{
        position : absolute;
        top : 0;
        left : 0;
        
    } */

}

@media (max-width : 1024px){
    .Projects .tech-stack{
        width : 80%;
    }

    .Projects .code-link{
        width : 32%;
    }

    .Projects .project-heading{
        grid-template-columns : 1fr 1fr;
    }
}

@media (max-width : 768px){

    .Projects *{
        text-align : left !important;
    }
    .Projects .project-heading{
        grid-template-columns : 1.5fr 1fr;
    }

    .Projects .image-container{
        position : absolute;
        top : 0;
        left : 0;
        width : 100%;
        height : 100%;
       
    }

    .Projects .image-container::after{
        content : "";
        position : absolute;
        top : 0;
        height : 100%;
        width : 100%;
        background-color : #1A1A1D;;
        opacity : 0.85;
        left : 0%;
        z-index : 4;
        transition : all 0.2s linear;
        filter : brightness(50%);
    }
    .Projects .featured-project{
        color : rgb(228, 228, 197);
    }
    .Projects .project{
        position : relative;
        grid-template-columns : 1fr;
        grid-template-rows : auto;
        padding : 30px 0 30px 0;
    }
    .Projects .project-details{
        grid-column : 1 / 3;
        width : 100%;
        display : grid;
        grid-template-columns : 1fr;
        grid-template-rows : auto;
        position : relative;
        z-index : 5;
        padding : 0 8px 0 8px;
        align-self : center;
    }

    .Projects .project-description{
        background-color : transparent;
        padding : 0;
        margin : 0;
        position : static;
        width : 90%;
    }

    .Projects .right-details{
        grid-column : 1 / 3;
        align-self : center;
        padding : 0 8px 0 8px;
    }
    .Projects .right-details{
        grid-column : 1 / 3;
        align-self : center;
        padding : 0 15px 0 15px;
    }

    .Projects .left-details{
        grid-column : 1 / 3;
        align-self : center;
        padding : 30px 15px 30px 15px;
    }

    .Projects .left-image{
        grid-column : 1 / 3;

    }
    
    .Projects .left-description{
        width : 90%;
    }
    .tech-stack p {
        color : rgb(228, 228, 197);
        font-size : 14px;
    }

    .code-link a{
        border : 1px solid rgb(228, 228, 197);
        padding : 5px;
        color : rgb(228, 228, 197);
        text-decoration: none;
        line-height : 1.5;
        font-size : 14px;
        
    }

    .code-link{
        margin-left : 0;
        width : 23%;
    }

    .tech-stack{
        margin-left : 0;
    }
}
